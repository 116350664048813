'use client';

import {
  UilArrowLeft,
  UilBell,
  UilCommentQuestion,
  UilHome,
  UilSetting,
  UilSubject,
  UilUserCircle,
} from '@iconscout/react-unicons';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { ReactNode, useContext, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import logo from '@/assets/home/logo.png';
import { LayoutContext } from '@/components/Layout';
// eslint-disable-next-line import/no-cycle
import ProfileBlock from '@/components/Molecule/Profile/ProfileBlock';
import { showFrontMessengerPanel } from '@/components/ReactClientComponents/FrontChat';
import { env } from '@/env.mjs';
import { useAuth } from '@/hooks/useAuth';
import { InternalLink, useInternalRouter } from '@/hooks/useInternalRouter';
import Join from '@/utils/Join';

import MaxMdOnly from 'src/components/Layout/MaxMdOnly';
import MdOnly from 'src/components/Layout/MdOnly';

function AppBar(props: {
  start?: ReactNode;
  center?: ReactNode;
  end?: ReactNode;
  backgroundColor: `#F8F8F8` | `#FFFFFF`;
}) {
  const { start = <AppBar.MenuButton />, center, end, backgroundColor } = props;

  const { me } = useAuth();
  const menus = useMemo(() => {
    if (me) {
      return (
        <Join by={<div className="max-w-24 grow" />}>
          {me.isDoorPerson && <DoorButton />}
          <MyShowsButton />
          <ShowHistoryButton />
        </Join>
      );
    }

    return null;
  }, [me]);

  return (
    <>
      <MaxMdOnly>
        <div
          className={twMerge(
            `sticky inset-x-0 top-0 z-10`,
            backgroundColor === `#F8F8F8` ? `bg-[#F8F8F8]` : ``,
            backgroundColor === `#FFFFFF` ? `bg-[#FFFFFF]` : ``,
          )}
        >
          <div className="flex items-center justify-between px-16 py-13">
            <div className="flex items-center gap-16">
              {start}
              {center}
            </div>
            <div className="relative flex gap-24">
              <AppBar.FrontButton />
              {end}
            </div>
          </div>
        </div>
      </MaxMdOnly>
      <MdOnly>
        <div className="sticky inset-x-0 top-0 z-10">
          <div className="flex h-62 w-full items-center gap-24 border border-solid border-gray-400 bg-[#fefcfb] px-40">
            <div className="flex grow gap-24">
              <AppBar.LogoButton />
            </div>
            <div className="flex grow items-center justify-center">{menus}</div>
            <div className="flex grow justify-end gap-40 text-right">
              <ProfileBlock />
            </div>
          </div>
        </div>
      </MdOnly>
    </>
  );
}

function BackButton() {
  const router = useInternalRouter();

  return (
    <button
      className="flex size-30 items-center justify-center"
      onClick={() => router.back()}
    >
      <UilArrowLeft className="size-30 fill-gray-25" />
    </button>
  );
}

function MenuButton() {
  const { setIsDrawerOpen } = useContext(LayoutContext);

  return (
    <button
      className="flex size-28 items-center justify-center"
      onClick={() => setIsDrawerOpen(true)}
    >
      <UilSubject className="size-28 fill-gray-25" />
    </button>
  );
}

function FrontButton() {
  if (!env.NEXT_PUBLIC_FRONT_CHAT_ID) {
    return null;
  }

  return (
    <button
      className="flex size-28 items-center justify-center"
      onClick={() => showFrontMessengerPanel()}
    >
      <UilCommentQuestion className="size-28 fill-gray-25" />
    </button>
  );
}

function Title(props: { title?: string }) {
  const { title } = props;

  return <span className="label-small">{title}</span>;
}

function NotificationButton() {
  return (
    <InternalLink href="/notifications">
      <button className="flex-center relative size-28 md:size-24">
        <UilBell className="size-28 fill-gray-25" />
        <div className="absolute right-0 top-0 size-[3.2px] rounded-full bg-danger-red-100" />
      </button>
    </InternalLink>
  );
}

function HomeButton() {
  return (
    <InternalLink href="/">
      <button className="flex size-28 items-center justify-center">
        <UilHome className="size-28 fill-gray-25" />
      </button>
    </InternalLink>
  );
}

function ProfileButton() {
  return (
    <InternalLink href="/profile">
      <button className="flex size-28 items-center justify-center">
        <UilUserCircle className="size-28 fill-gray-25" />
      </button>
    </InternalLink>
  );
}

function SettingButton() {
  return (
    <InternalLink href="/settings">
      <button className="flex size-28 items-center justify-center">
        <UilSetting className="size-28 fill-gray-25" />
      </button>
    </InternalLink>
  );
}

function LogoButton() {
  return (
    <InternalLink href="/" className="contents">
      <div className="h-30 shrink-0">
        <Image src={logo} width={120} height={30} alt="logo" />
      </div>
    </InternalLink>
  );
}

function LogoutButton() {
  const { logout } = useAuth();

  return (
    <div className="flex-center">
      <button className="header-5-m text-gray-25" onClick={logout}>
        Log Out
      </button>
    </div>
  );
}

function LearnMoreButton() {
  return (
    <Link href="https://www.gigfinesse.com/">
      <div className="flex-center">
        <span className="header-5-m text-gray-25">Learn more</span>
      </div>
    </Link>
  );
}

function LoginButton() {
  return (
    <InternalLink href="/signin">
      <div className="flex-center">
        <span className="header-5-m text-gray-25">Log in</span>
      </div>
    </InternalLink>
  );
}

function SignUpButton() {
  return (
    <InternalLink href="/signup">
      <div className="flex-center">
        <span className="header-5-m text-gray-25">Sign up</span>
      </div>
    </InternalLink>
  );
}

function MyShowsButton() {
  const pathname = usePathname();
  const { me } = useAuth();

  if ((me?.artists.length ?? 0) === 0) {
    return null;
  }

  return (
    <InternalLink href="/slots">
      <div className="flex-center p-10">
        <span
          className={twMerge(
            `header-5-m`,
            pathname.match(/^\/slots.*$/) ? `text-gray-25` : `text-gray-100`,
          )}
        >
          My Shows
        </span>
      </div>
    </InternalLink>
  );
}

function ShowHistoryButton() {
  const pathname = usePathname();
  const { me } = useAuth();

  if ((me?.artists.length ?? 0) === 0) {
    return null;
  }

  return (
    <InternalLink href="/history">
      <div className="flex-center p-10">
        <span
          className={twMerge(
            `header-5-m`,
            pathname === `/history` ? `text-gray-25` : `text-gray-100`,
          )}
        >
          Show History
        </span>
      </div>
    </InternalLink>
  );
}

function DoorButton() {
  const pathname = usePathname();

  return (
    <InternalLink href="/door/calendar">
      <div className="flex-center p-10">
        <span
          className={twMerge(
            `header-5-m`,
            pathname === `/door/calendar` ? `text-gray-25` : `text-gray-100`,
          )}
        >
          Door
        </span>
      </div>
    </InternalLink>
  );
}

AppBar.BackButton = BackButton;
AppBar.MenuButton = MenuButton;
AppBar.FrontButton = FrontButton;
AppBar.Title = Title;
AppBar.NotificationButton = NotificationButton;
AppBar.HomeButton = HomeButton;
AppBar.ProfileButton = ProfileButton;
AppBar.SettingButton = SettingButton;
AppBar.LogoButton = LogoButton;
AppBar.LogoutButton = LogoutButton;
AppBar.LearnMoreButton = LearnMoreButton;
AppBar.LoginButton = LoginButton;
AppBar.SignUpButton = SignUpButton;
AppBar.MyShowsButton = MyShowsButton;
AppBar.ShowHistoryButton = ShowHistoryButton;
AppBar.DoorButton = DoorButton;

export default AppBar;
